var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.isLoaded
    ? _c("detail-page", {
        ref: _vm.detailPageRefName,
        attrs: {
          formModel: _vm.model,
          formSchema: _vm.schema,
          tooltipMsg: "manualforinvoic3299",
        },
        scopedSlots: _vm._u(
          [
            {
              key: "header",
              fn: function () {
                return [
                  _c("label", [
                    _vm._v(_vm._s(_vm.$t("detailofinvoice")) + " "),
                  ]),
                  _c("invoice-link", {
                    attrs: {
                      iNumber:
                        _vm.model.Type == _vm.invoiceTypeEnum.Invoice
                          ? _vm.model.Number
                          : "",
                      iCode:
                        _vm.model.Type == _vm.invoiceTypeEnum.Invoice
                          ? _vm.model.Code
                          : "",
                      iPFNumber:
                        _vm.model.Type == _vm.invoiceTypeEnum.Proforma
                          ? _vm.model.Number
                          : "",
                      iPFCode:
                        _vm.model.Type == _vm.invoiceTypeEnum.Proforma
                          ? _vm.model.Code
                          : "",
                      iType: _vm.model.Type,
                    },
                  }),
                  _c("label", [
                    _vm._v(
                      " - " +
                        _vm._s(_vm.$t("id")) +
                        " F" +
                        _vm._s(_vm.model.InvoiceId) +
                        " "
                    ),
                  ]),
                  _vm.model.OtherInvoicesCount > 0
                    ? _c("b-badge", { attrs: { variant: "danger" } }, [
                        _vm._v(
                          "\n\t\t\t+" +
                            _vm._s(_vm.model.OtherInvoicesCount) +
                            "x " +
                            _vm._s(_vm.$t("user")) +
                            "\n\t\t"
                        ),
                      ])
                    : _vm._e(),
                ]
              },
              proxy: true,
            },
            {
              key: "beforeForm",
              fn: function () {
                return [
                  _c("remark", {
                    attrs: {
                      entityId: _vm.detailEntityId,
                      backmethod: _vm.redirectToTable,
                      entityTypeId: _vm.remarkEntityType,
                      remarkTypeId: _vm.remarkType,
                      administratorList: _vm.administratorList,
                    },
                  }),
                  _c("remark-accountant", {
                    key: _vm.remarkAccountantKey,
                    attrs: { entityId: _vm.detailEntityId },
                  }),
                  _c("email-send-dropdown", {
                    attrs: {
                      labelText: _vm.$t("e-mails:"),
                      buttonText: _vm.$t("fornewyear'sev"),
                      pesLogTypeId: _vm.pesLogTypeId,
                      offersList: _vm.offersList,
                      entityId: _vm.detailEntityId,
                      emailExist: _vm.model.EmailExists,
                      withTable: true,
                    },
                  }),
                ]
              },
              proxy: true,
            },
            {
              key: "formActions",
              fn: function () {
                return [
                  _c("megau-button", {
                    attrs: {
                      classprop: "btn btn-success",
                      icon: "check",
                      title: _vm.$t("savechangesandn"),
                    },
                    on: {
                      handleClick: function ($event) {
                        return _vm.saveModel(false, false)
                      },
                    },
                  }),
                  _vm.model.InvoiceDatetimePayment
                    ? _c("megau-button", {
                        attrs: {
                          classprop: "btn btn-success",
                          icon: "check",
                          title: _vm.$t("generatepdfinvo"),
                        },
                        on: {
                          handleClick: function ($event) {
                            return _vm.regenerate()
                          },
                        },
                      })
                    : _vm._e(),
                  _c("megau-button", {
                    attrs: {
                      classprop: "btn btn-primary",
                      icon: "undo",
                      title: _vm.$t("cancel2168"),
                    },
                    on: {
                      handleClick: function ($event) {
                        return _vm.redirectToTable()
                      },
                    },
                  }),
                ]
              },
              proxy: true,
            },
            {
              key: "afterForm",
              fn: function () {
                return [
                  _c("products-section", {
                    ref: "productsSection",
                    attrs: {
                      invoiceId: _vm.detailEntityId,
                      editable: !(
                        _vm.model.InvoiceIsCancelled ||
                        _vm.model.InvoiceDatetimePayment
                      ),
                      userId: _vm.model.UserId,
                      objectId: _vm.model.ObjectId,
                      refresh: _vm.refreshProducts,
                      objectCountryId: _vm.model.ObjectCountryId,
                    },
                  }),
                  _c("invoice-item-table", {
                    attrs: {
                      tableKey: _vm.tableKey,
                      invoiceId: _vm.detailEntityId,
                    },
                  }),
                ]
              },
              proxy: true,
            },
          ],
          null,
          false,
          1608652119
        ),
      })
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }