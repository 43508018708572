var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("b-card-title", { staticClass: "mt-4" }, [
        _vm._v(_vm._s(_vm.$t("timedistributio"))),
      ]),
      _c("v-server-table", {
        directives: [
          {
            name: "show",
            rawName: "v-show",
            value: _vm.showTable,
            expression: "showTable",
          },
        ],
        key: _vm.tableKey,
        ref: "invoiceItemTable",
        staticClass: "detail-table",
        attrs: {
          name: "invoiceItemTable",
          columns: _vm.columns,
          options: _vm.tableOptions,
        },
        scopedSlots: _vm._u([
          {
            key: "NumberRow",
            fn: function (list) {
              return [_vm._v(_vm._s(list.index) + ".")]
            },
          },
          {
            key: "ItemDateStart",
            fn: function (list) {
              return [_vm._v(_vm._s(_vm._f("date")(list.row.ItemDateStart)))]
            },
          },
          {
            key: "ItemDateEnd",
            fn: function (list) {
              return [_vm._v(_vm._s(_vm._f("date")(list.row.ItemDateEnd)))]
            },
          },
          {
            key: "ItemPriceTotalWithVat",
            fn: function (list) {
              return [
                _vm._v(
                  "\n\t\t\t" +
                    _vm._s(
                      _vm._f("currency")(
                        list.row.ItemPriceTotalWithVat,
                        _vm.getCurrencyById(list.row.CurrencyId)
                      )
                    ) +
                    "\n\t\t"
                ),
              ]
            },
          },
        ]),
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }