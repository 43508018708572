var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      directives: [
        {
          name: "show",
          rawName: "v-show",
          value: _vm.isVisibleForm,
          expression: "isVisibleForm",
        },
      ],
    },
    [
      _c("div", { staticClass: "remark" }, [
        _c("span", { staticClass: "titleRemark" }, [
          _vm._v(_vm._s(_vm.$t("object2502"))),
        ]),
        _c(
          "div",
          { staticClass: "dataRemark" },
          [
            _c("megau-button", {
              attrs: {
                classprop: "btn btn-default border",
                titlePosition: "none",
                title: this.buttonTitle,
              },
              on: {
                handleClick: function ($event) {
                  return _vm.openModal()
                },
              },
            }),
          ],
          1
        ),
      ]),
      _c(
        "b-modal",
        {
          attrs: {
            visible: _vm.isVisibleDetail,
            title: _vm.$t("remark"),
            size: "lg",
            "no-close-on-backdrop": "",
            centered: "",
          },
          on: {
            hidden: function ($event) {
              _vm.isVisibleDetail = false
            },
          },
          scopedSlots: _vm._u([
            {
              key: "modal-footer",
              fn: function (ref) {
                var ok = ref.ok
                var cancel = ref.cancel
                var hide = ref.hide
                return [
                  _c("megau-button", {
                    attrs: {
                      icon: "check",
                      title: _vm.$t("saveremark"),
                      classprop: "btn btn-success",
                    },
                    on: { handleClick: _vm.handleInsertOrUpdate },
                  }),
                  _c("megau-button", {
                    attrs: {
                      icon: "times",
                      title: _vm.$t("deleteremark"),
                      classprop: "btn btn-danger",
                    },
                    on: { handleClick: _vm.handleDelete },
                  }),
                ]
              },
            },
          ]),
        },
        [
          _c("remark", {
            ref: "Remark",
            attrs: {
              entityId: this.entityId,
              entityTypeId: 3,
              remarkTypeId: 2,
              loadedData: this.loadedData,
              isModal: "true",
              backmethod: _vm.hideModal,
            },
          }),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }