<template>
	<div v-show="isVisibleForm">
		<div class="remark">
			<span class="titleRemark">{{$t('object2502')}}</span>
			<div class="dataRemark">
				<megau-button
					classprop="btn btn-default border"
					titlePosition="none"
					:title="this.buttonTitle"
					@handleClick="openModal()"
				></megau-button>
			</div>
		</div>
		<b-modal
			:visible="isVisibleDetail"
			:title="$t('remark')"
			size="lg"
			no-close-on-backdrop
			centered
			@hidden="isVisibleDetail=false"
		>
			<remark
				ref="Remark"
				:entityId="this.entityId"
				:entityTypeId="3"
				:remarkTypeId="2"
				:loadedData="this.loadedData"
				isModal="true"
				:backmethod="hideModal"
			></remark>
			<template v-slot:modal-footer="{ ok, cancel, hide }">
				<megau-button
					icon="check"
					:title="$t('saveremark')"
					@handleClick="handleInsertOrUpdate"
					classprop="btn btn-success"
				></megau-button>
				<megau-button
					icon="times"
					@handleClick="handleDelete"
					:title="$t('deleteremark')"
					classprop="btn btn-danger"
				></megau-button>
			</template>
		</b-modal>
	</div>
</template>
<script>
import vue from 'vue';
import enums from '../../services/helpers/enums';
import http from '../../services/helpers/http';
import { formatter } from '../../services/helpers/formatter';

export default {
	props: {
		entityId: { type: Number },
	},
	name: 'remark-accountant',
	data() {
		return {
			isVisibleForm: false,
			loadedData: null,
			isVisibleDetail: false,
			refreshMethod: { type: Function },
		};
	},
	computed: {
		buttonTitle() {
			if (this.loadedData !== null && this.loadedData !== '') {
				return formatter.dateTimeToString(this.loadedData.Date) + ' ' + this.loadedData.Text;
			} else {
				return '';
			}
		},
	},
	mounted() {
		this.getRemark(this.entityId, enums.remarkEntityType.Invoice, enums.remarkType.Accountant);
	},
	methods: {
		async getRemark(entityId, entityTypeId, remarkTypeId) {
			let _this = this;
			http.getWithFilters('Remark/GetRemarkByIds', { entityId, entityTypeId, remarkTypeId }).then((resp) => {
				_this.loadedData = resp.data;
				if (_this.loadedData !== null && _this.loadedData !== '') {
					_this.isVisibleForm = true;
				} else {
					_this.isVisibleForm = false;
				}
			});
		},
		hideModal() {
			this.isVisibleDetail = false;
			this.getRemark(this.entityId, enums.remarkEntityType.Invoice, enums.remarkType.Accountant);
		},
		openModal() {
			this.isVisibleDetail = true;
		},
		closeDetail() {
			this.isVisibleDetail = false;
		},
		handleDelete() {
			this.$refs.Remark.handleDelete(true);
		},
		handleInsertOrUpdate() {
			this.$refs.Remark.handleInsertOrUpdate(true);
		},
	},
};
</script>
<style lang="scss">
.remark {
	margin-bottom: 10px;
	display: flex;
	flex-flow: row wrap;
	align-content: center;

	.titleRemark {
		@include formLabel;
		order: 0;
		margin-top: 7px;
	}

	.dataRemark {
		width: 50%;
	}
}
</style>
