<template>
	<div>
		<b-card-title class="mt-4">{{ $t('timedistributio') }}</b-card-title>
		<v-server-table
			v-show="showTable"
			name="invoiceItemTable"
			:columns="columns"
			:options="tableOptions"
			ref="invoiceItemTable"
			class="detail-table"
			:key="tableKey"
		>
			<template #NumberRow="list">{{ list.index }}.</template>

			<template #ItemDateStart="list">{{ list.row.ItemDateStart | date }}</template>

			<template #ItemDateEnd="list">{{ list.row.ItemDateEnd | date }}</template>

			<template #ItemPriceTotalWithVat="list">
				{{ list.row.ItemPriceTotalWithVat | currency(getCurrencyById(list.row.CurrencyId)) }}
			</template>
		</v-server-table>
	</div>
</template>

<script>
import { formatter } from '../../../services/helpers/formatter';
import vars from '../../../services/helpers/variables';
import serviceCommon from '../../../services/service/common.service';
import serviceEnums from '../../../services/service/enums.service';
import http from '../../../services/helpers/http';
import i18n from '../../../services/lang';

export default {
	props: {
		invoiceId: { type: Number, default: 0 },
		tableKey: { type: Number },
	},
	data() {
		return {
			showTable: false,
			currencyDict: null,
			controllerName: 'InvoiceItem',
			columns: ['NumberRow', 'ItemName', 'ItemDateStart', 'ItemDateEnd', 'ItemPriceTotalWithVat'],
		};
	},
	computed: {
		tableOptions() {
			return {
				preserveState: true,
				columns: 'Cols',
				sortable: [],
				texts: serviceCommon.recordsPaginationsTexts(),
				filterable: false,
				headings: {
					NumberRow: i18n.t('s.no.'),
					ItemName: i18n.t('packagename'),
					ItemDateStart: i18n.t('validfrom'),
					ItemDateEnd: i18n.t('validto2420'),
					ItemPriceTotalWithVat: i18n.t('totalprice'),
				},
				requestFunction: (data) => {
					return http
						.get('InvoiceItem/GetListByInvoiceId', {
							invoiceId: this.invoiceId,
						})
						.then((resp) => {
							if (resp.data.length > 0) {
								this.showTable = true;
							} else {
								this.showTable = false;
							}

							return resp;
						});
				},
			};
		},
	},
	created() {
		this.getCurrencyEnum();
	},
	methods: {
		getCurrencyEnum() {
			this.currencyDict = serviceEnums.getEnumDict('currency');
		},
		getCurrencyById(currencyId) {
			return this.currencyDict[currencyId].Text;
		},
	},
};
</script>

<style lang="scss">
@import '@/styles/components/detail-table.scss';
</style>
