import vueFormGenerator from 'vue-form-generator';
import { formatter } from '../../services/helpers/formatter';

export const model = {
	InvoiceId: null,
	GuserId: null,
	OtherInvoicesCount: null,
	Number: null,
	Code: null,
	Type: null,
	EmailExists: false,
	ObjectId: null,
	ObjectUrl: null,
	InvoiceAddressId: 0,
	InvoiceAddress: null,
	ReservationTotalCount: null,
	ReservationLastYearCount: null,
	RealizedReservationLastYearCount: null,
	AutologCode: null,
	InvoiceNumber: null,
	InvoiceType: null,
	InvoiceIsReinvoice: null,
	InvoiceVariableSymbol: null,
	InvoiceDatetimeCreated: null,
	InvoiceDatetimeMaturity: null,
	InvoiceDatetimePayment: null,
	InvoiceDatetimeTaxable: null,
	Send: null,
	InvoiceAutomaticRemarksSendDate: null,
	ObjectCountryId: null,
	InvoiceContacts: null,
	InvoiceContactsNote: null,
	InvoiceLinks: null,
	InvoiceIsCancelled: null,
};

export const fields = [
	{
		fields: [
			{
				type: 'contact-box',
				model: 'InvoiceContacts',
				i18n: {
					label: 'contacts2500',
				},
			},
			{
				type: 'link-box',
				model: 'InvoiceLinks',
				i18n: {
					label: 'usefuldata',
				},
			},
			{
				type: 'accounting-company',
				model: 'AccountingCompanyUsed',
				i18n: {
					label: 'supplier2504',
				},
			},
			{
				type: 'single-datepicker',
				model: 'InvoiceAutomaticRemarksSendDate',
				required: false,
				autoselect: true,
				language: 'sk',
				showtime: false,
				validator: vueFormGenerator.validators.date,
				i18n: {
					label: 'sendremarksafte',
					help: 'ifadateisentere',
				},
			},
			{
				type: 'label',
				model: 'InvoiceType',
				i18n: {
					label: 'typeofinvoice',
					get: (i18n) => (model) =>
						model && model.InvoiceType === 1 ? i18n.t('proformainvoice2513') : i18n.t('invoice2514'),
				},
			},
			{
				type: 'switch',
				model: 'InvoiceIsReinvoice',
				readonly: true,
				featured: false,
				disabled: true,
				default: true,
				i18n: {
					label: 'isreinvoice',
					textOn: 'yes2220',
					textOff: 'no2221',
				},
			},
			{
				type: 'label',
				model: 'InvoiceNumber',
				i18n: {
					label: 'invoicenumber',
				},
			},
			{
				type: 'input',
				inputType: 'text',
				model: 'InvoiceVariableSymbol',
				required: true,
				styleClasses: 'half-width',
				validator: vueFormGenerator.validators.string,
				i18n: {
					label: 'variablesymbol2517',
				},
			},
			{
				type: 'input',
				inputType: 'text',
				model: 'InvoiceText',
				required: true,
				validator: vueFormGenerator.validators.string,
				i18n: {
					label: 'invoicetext',
				},
			},
			{
				type: 'single-datepicker',
				model: 'InvoiceDatetimeCreated',
				required: true,
				autoselect: true,
				language: 'sk',
				showtime: false,
				validator: vueFormGenerator.validators.date,
				i18n: {
					label: 'issuedate',
				},
			},
			{
				type: 'single-datepicker',
				model: 'InvoiceDatetimeMaturity',
				required: true,
				autoselect: true,
				language: 'sk',
				showtime: false,
				validator: vueFormGenerator.validators.date,
				i18n: {
					label: 'duedate2519',
				},
			},
			{
				type: 'single-datepicker',
				model: 'InvoiceDatetimeTaxable',
				required: true,
				autoselect: true,
				language: 'sk',
				showtime: false,
				validator: vueFormGenerator.validators.date,
				i18n: {
					label: 'taxdate2520',
				},
			},
			{
				type: 'label',
				model: 'InvoiceDatetimePayment',
				i18n: {
					label: 'paymentdate',
					get: (i18n) => (model) =>
						model && model.InvoiceDatetimePayment
							? formatter.dateToString(model.InvoiceDatetimePayment)
							: i18n.t('unpaidinvoice'),
				},
			},
			{
				type: 'invoice-address',
				model: 'InvoiceAddressId',
				showQuestion: false,
				onChanged(model) {
					this.$parent.$parent.$parent.remarkAccountantKey += 1;
				},
				visible(model) {
					return model && model.InvoiceDatetimePayment === null;
				},
				i18n: {
					label: 'billingaddress',
				},
			},
			{
				type: 'invoice-address-invoice',
				model: 'InvoiceAddress',
				onChanged(model) {
					this.$parent.$parent.$parent.remarkAccountantKey += 1;
				},
				visible(model) {
					return model && model.InvoiceDatetimePayment !== null;
				},
				i18n: {
					label: 'billingaddress',
				},
			},
			{
				type: 'textArea',
				model: 'InvoiceNote',
				min: 1,
				rows: 4,
				validator: vueFormGenerator.validators.string,
				i18n: {
					label: 'remark:',
				},
			},
		],
	},
];
