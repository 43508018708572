<template>
	<detail-page v-if="isLoaded" :formModel="model" :formSchema="schema" :ref="detailPageRefName" tooltipMsg="manualforinvoic3299">
		<template #header>
			<label>{{ $t('detailofinvoice') }}&nbsp;</label>

			<invoice-link
				:iNumber="model.Type == invoiceTypeEnum.Invoice ? model.Number : ''"
				:iCode="model.Type == invoiceTypeEnum.Invoice ? model.Code : ''"
				:iPFNumber="model.Type == invoiceTypeEnum.Proforma ? model.Number : ''"
				:iPFCode="model.Type == invoiceTypeEnum.Proforma ? model.Code : ''"
				:iType="model.Type"
			></invoice-link>

			<label>&nbsp;-&nbsp;{{ $t('id') }}&nbsp;F{{ model.InvoiceId }}&nbsp;</label>

			<b-badge v-if="model.OtherInvoicesCount > 0" variant="danger">
				+{{ model.OtherInvoicesCount }}x&nbsp;{{ $t('user') }}
			</b-badge>
		</template>

		<template #beforeForm>
			<remark
				:entityId="detailEntityId"
				:backmethod="redirectToTable"
				:entityTypeId="remarkEntityType"
				:remarkTypeId="remarkType"
				:administratorList="administratorList"
			></remark>

			<remark-accountant :key="remarkAccountantKey" :entityId="detailEntityId"></remark-accountant>

			<email-send-dropdown
				:labelText="$t('e-mails:')"
				:buttonText="$t(`fornewyear'sev`)"
				:pesLogTypeId="pesLogTypeId"
				:offersList="offersList"
				:entityId="detailEntityId"
				:emailExist="model.EmailExists"
				:withTable="true"
			></email-send-dropdown>
		</template>

		<template #formActions>
			<megau-button
				classprop="btn btn-success"
				icon="check"
				:title="$t('savechangesandn')"
				@handleClick="saveModel(false, false)"
			></megau-button>

			<megau-button
				v-if="model.InvoiceDatetimePayment"
				classprop="btn btn-success"
				icon="check"
				:title="$t('generatepdfinvo')"
				@handleClick="regenerate()"
			></megau-button>

			<megau-button
				classprop="btn btn-primary"
				icon="undo"
				:title="$t('cancel2168')"
				@handleClick="redirectToTable()"
			></megau-button>
		</template>

		<template #afterForm>
			<products-section
				ref="productsSection"
				:invoiceId="detailEntityId"
				:editable="!(model.InvoiceIsCancelled || model.InvoiceDatetimePayment)"
				:userId="model.UserId"
				:objectId="model.ObjectId"
				:refresh="refreshProducts"
				:objectCountryId="model.ObjectCountryId"
			></products-section>

			<invoice-item-table :tableKey="tableKey" :invoiceId="detailEntityId"></invoice-item-table>
		</template>
	</detail-page>
</template>

<script>
import vue from 'vue';
import DetailPage from '@/components/general/detail-page';
import { detailPageMixin } from '@/components/general/detail-page.mixin';
import { backlinkDetailName } from '@/router/links';
import { model, fields } from './invoice-form.data';
import enums from '../../services/helpers/enums';

import EmailSendDropdown from '../../modules/emails/email-send.dropdown';
import FieldInvoiceAddressInvoice from './invoice-address-invoice/invoice-address-invoice-vfg.field.vue';
import RemarkAccountant from './invoice-remark.field.vue';
import ProductsSection from '../../modules/user-products/products.section';
import InvoiceItemTable from './invoice-item/invoice-item.table';

import service from './invoice.service';
import servicePortalEmail from '../../modules/portal-emails/portal-email.service';
import * as links from '../.././router/links';
import http from '../../services/helpers/http';
import loading from '../../mixins/loading.mixin';
import apiUser from '../../services/api/user.api';
import auth from '../../services/auth.service';

import { EventBus } from '../../services/helpers/event-bus.js';

export default {
	components: {
		DetailPage,
		EmailSendDropdown,
		FieldInvoiceAddressInvoice,
		RemarkAccountant,
		ProductsSection,
		InvoiceItemTable,
	},

	mixins: [loading, detailPageMixin],

	data() {
		return {
			controllerName: 'Invoice',
			routeParam: 'InvoiceId',
			administratorList: [],
			remark: null,
			tableKey: 0,
			remarkAccountantKey: 0,
			offersList: [],
			invoiceTypeEnum: enums.invoiceType,
			remarkEntityType: enums.remarkEntityType.Invoice,
			remarkType: enums.remarkType.Default,
			pesLogTypeId: enums.sendLogsTypeEnum.Invoice,
			model,
			schema: {
				groups: fields,
			},
		};
	},

	computed: {
		tablePath() {
			return links.invoicesTable;
		},
	},

	created() {
		/* TODO overit ci to netreba presunut do globalneho nastavenia
		const res = vueFormGenerator.validators.resources;
		res.fieldIsRequired = this.$i18n.t('amistake!');
		*/
	},

	methods: {
		async loadResources() {
			EventBus.$on('change-note', () => {
				this.onChangeNote();
			});

			await Promise.all([this.getEmailSubjects(), this.getAdministrators()]);

			this.user = auth.GetUser();
		},

		async onChangeNote() {
			this.startLoading();
			await this.loadModel().finally(() => this.stopLoading());
		},

		async getAdministrators() {
			const resp = await apiUser.getAdministratorList();
			this.administratorList = resp.data;
			this.administratorList.unshift({ id: null, name: this.$i18n.t('unassigned') });
		},

		async getEmailSubjects() {
			const emailIds = '58,5,68,69,3,70,71,72,22';
			const resp = await servicePortalEmail.getEmailSubjectsByIds(emailIds);
			this.offersList = resp.data;
		},

		async loadModel(id = null) {
			const resp = await http.get('invoice/GetDetailById', { id: id || this.detailEntityId });
			const model = this.transformDtoToModel(resp.data);
			this.updateFormModel(model, true);
		},
		transformDtoToModel(dtoModel) {
			dtoModel.InvoiceContacts.ObjectWebBacklinks = dtoModel.InvoiceContacts.ObjectWebBacklinks.map((b) => ({
				...b,
				CreationDate: new Date(b.CreationDate),
				linkToDetail: this.$router.resolve({ name: backlinkDetailName, params: { Id: b.Id } }).href,
				State: b.State
			}));
			return dtoModel;
		},

		async regenerate() {
			await http.post('invoice/CreatePdfByInvoice/' + this.detailEntityId);
		},

		async saveModel(Send, closeOnSuccess) {
			this.startLoading();

			const isValid = await this.$refs[this.detailPageRefName].validate();
			if (!isValid) {
				this.stopLoading();
				return;
			}

			this.updateFormModel({ Send });
			await http
				.put('invoice/UpdateDetail', this.model)
				.then((res) => res.data)
				.then((res) => {
					EventBus.$emit('change-note-from-detail');
					return res;
				})
				.finally(() => this.stopLoading());

			if (closeOnSuccess) {
				return this.redirectToTable();
			}

			this.redirectToDetail(this.detailEntityId);
		},

		refreshProducts() {
			this.tableKey += 1;
			this.remarkAccountantKey += 1;
		},
	},
};
</script>
